import React from "react";
import { Layout } from "antd";
import { Home } from "./pages/home/Home";
import { Footer } from "./pages/common/Footer";

const { Content } = Layout;

function App() {
  return (
    <Layout style={{ height: "100%" }}>
      <Content>
        <Home></Home>
      </Content>
      <Footer></Footer>
    </Layout>
  );
}

export default App;
