import React from "react";
import { Divider, Image } from "antd";
import codeImage from "../../assets/code.jpg";

export const Home = () => {
  return (
    <section
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <h3 style={{ textAlign: "center" }}>最近有点烦</h3>
        <Divider />
        <Image width={300} src={codeImage} />
      </div>
    </section>
  );
};
