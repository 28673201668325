import React from "react";
import { Button, Layout } from "antd";
const { Footer: FooterComponent } = Layout;

export const Footer = () => {
  return (
    <FooterComponent
      style={{ textAlign: "center", borderTop: "1px solid #ddd" }}
    >
      <Button type="link" href="https://beian.miit.gov.cn" target="_blank">
        鄂ICP备2021020937号-1
      </Button>
    </FooterComponent>
  );
};
